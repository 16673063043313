//input
.input-group-col {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}
.input-item {
    align-items: flex-end;
}
//cursor
.cursor-pointer {
    cursor: pointer;
}

//dataentry
.doc-word {
    background-color: rgba(255, 255, 23, 0);
    position: absolute;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    overflow: hidden;
    color: rgba(0, 0, 0, 0);
    z-index: 10;
}
.doc-word:hover {
    border: 3px dotted red;
}
.word-selected {
    background-color: rgba(251, 255, 23, 0.55) !important;
}
.word-current-selection {
    background-color: rgba(255, 94, 0, 0.2);
}
.word-only-a-letter {
    transform: scale(2);
}
.button-anomalia-select {
    .popover-wrapper {
        &::part(content)  {
            ion-select-popover {
                ion-list {
                    ion-radio-group {
                        ion-item {
                            ion-label {
                                white-space: normal !important;
                            }
                            ion-radio {
                                width: 0px !important;
                                height: 0px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
.word-wizard-highlight {
    background: rgb(255, 255, 0, 0.2) !important;
}
.word-search-highlight {
    background: rgba(255, 255, 0, 0.7) !important;
}
/* split mode */
.splitModeRowHr {
    display: block;
    border-bottom: solid 3px rgba(0, 0, 255, 0.3);
    position: absolute;
}
.splitModeRowHrLastChild {
    border: 0px;
}

.dotLeft {
    position: absolute;
    background: blue;
    height: 10px;
    width: 10px;
}
.dotRight {
    position: absolute;
    background: var(--ion-color-danger);
    color: #fff;
    height: 10px;
    width: 10px;
    font-size: 10px;
    overflow: hidden;
    cursor: pointer;
    z-index: 13;
    display: flex;
    align-items: center;
    justify-content: center;
}
.splitModeColumn {
    position: absolute;
    width: 3px;
    border-right: solid 3px rgba(0, 0, 255, 0.3);
}
.splitModeColumn:hover {
    background-color: rgba(0, 0, 255, 0.2);
}
.splitModeColumnHeader {
    position: absolute;
}
.headerColumn {
    height: 30px;
    position: absolute;
    background: blue;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    padding-left: 3px;
    span {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.splitModeCanvas {
    background: rgba(0, 0, 0, 0);
    border: solid 3px rgba(0, 0, 255, 0.3);
}
.dom-table-discarded-row {
    background: rgba(var(--ion-color-tertiary-rgb), 0.6);
}
.highlight-character {
    background: rgba(var(--ion-color-warning-rgb), 0.3);
    color: #333;
}
.highlight-character-pad {
    background: rgba(var(--ion-color-warning-rgb), 0.3);
    color: transparent;
}
/* popover */
.button-anomalia-popover {
    &::part(content)  {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw;
        max-width: 900px;
        max-height: 90vh;
    }
}
.admin-queue-permission-modal {
    &::part(content)  {
        width: 900px;
        max-width: 90%;
    }
}
.alert-jsCheckMessageTable {
    .alert-wrapper {
        max-width: 90% !important;
        width: 900px !important;
    }
}
.input-selectable-modal-component {
    &::part(content)  {
        ion-card-header {
            background-color: var(--ion-color-secondary) !important;
        }
        max-width: 90% !important;
        width: 900px !important;
        max-height: 90vh;
        height: 800px;
    }
}
.input-selectable-modal-component-hidden {
    --box-shadow: none !important;
    box-shadow: none !important;
    &::part(content)  {
        //background: transparent !important;
        max-height: 50px;
        height: 50px;
        position: absolute;
        top: 5px;
        ion-card-header {
            background-color: var(--ion-color-secondary) !important;
        }
        ion-content,
        ion-footer {
            opacity: 0 !important;
            height: 0px !important;
            max-height: 0px !important;
            min-height: 0px !important;
            overflow: hidden !important;
        }
    }
}
.add-import-popover {
    &::part(content)  {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw;
        max-width: 700px;
        max-height: 90vh;
    }
}
.controllo-qualita-accettazione {
    &::part(content)  {
        position: relative !important;
        margin: 0 auto !important;
        left: auto !important;
        top: auto !important;
        width: 90vw;
        max-width: 700px;
        max-height: 90vh;
    }
}
.shortcut-modal {
    .alert-wrapper {
        max-width: 600px !important;
        width: 90% !important;
        .alert-message {
            max-height: 400px !important;
        }
    }
}
.dbtext-merge-data-component {
    &::part(content)  {
        max-width: 90% !important;
        width: 900px !important;
        max-height: 90% !important;
        height: 100% !important;
    }
}
.dataentry-attachment-modal {
    &::part(content)  {
        max-width: 90% !important;
        width: 100% !important;
        max-height: 90% !important;
        height: 100% !important;
    }
}
.qa-document-preview {
    &::part(content)  {
        max-width: 90% !important;
        width: 100% !important;
        max-height: 90% !important;
        height: 100% !important;
    }
}
.country-date-popover{
    &::part(content)  {
        width: auto !important;
    }
}
/* selectable */
.selectable-no-results {
    padding: 10px;
}

/* shortcut */
.button-span {
    padding: 1px;
    padding-left: 3px;
    padding-right: 3px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #333;
    border-radius: 4px;
    margin-left: 3px;
    margin-right: 3px;
}
.shortcut-table {
    margin-top: 10px;
    th,
    td {
        padding: 5px;
        padding-left: 0px;
    }
}
/* doc pages preview */
#docPagesPreview {
    max-height: 200px;
    background-color: var(--ion-color-primary);
    overflow: hidden;
}

/* admin queue */
.admin-queue-permission-modal {
    ionic-selectable {
        font-size: 14px !important;
        .ionic-selectable-value {
            padding-top: 5px !important;
            padding-bottom: 0px !important;
        }
        .ionic-selectable-icon-inner {
            top: 14px !important;
        }
    }
}

/* input selectable */
.input-selectable-modal-component {
}

/* jsCheckMessageTable */
.jsCheckMessageTable {
    width: 100%;
    table-layout: fixed;
    overflow-wrap: break-word;
    .jsCheckMessageTable-row {
        min-width: 50px;
        width: 50px;
        max-width: 50px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .jsCheckMessageTable-field {
        min-width: 200px;
        width: 200px;
        max-width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .jsCheckMessageTable-error {
        min-width: calc(100% - 250px);
        width: calc(100% - 250px);
        max-width: calc(100% - 250px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

/*attachment-modal*/
.attachment-modal {
    ngx-file-drop {
        height: 100% !important;
        width: 100% !important;
    }
    .dropzone-card-content {
        height: 100% !important;
        width: 100% !important;
    }
    .ngx-file-drop__content {
        height: 100% !important;
        width: 100% !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }
}
/* controllo qualita dettaglio */
.controllo-qualita-page {
    app-controllo-qualita-item {
        width: 100%;
        mat-expansion-panel {
            border-radius: 0px !important;
        }
        mat-panel-title {
            margin-right: 5px !important;
            max-width: 100%;
        }
        mat-expansion-panel-header {
            height: auto !important;
            margin: 0 !important;
            padding-top: 0px !important;
            padding-left: 0px !important;
            padding-right: 10px !important;
            padding-bottom: 0px !important;
            background-color: var(--ion-color-primary) !important;
            color: var(--ion-color-primary-contrast) !important;
        }
        .mat-expansion-panel-body {
            padding: 0px !important;
        }
        .mat-expansion-panel-header.mat-expanded:focus,
        .mat-expansion-panel-header.mat-expanded:hover,
        .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
            background: var(--ion-color-primary) !important;
        }
        .mat-expansion-indicator {
            padding-bottom: 5px;
        }
        .mat-expansion-indicator::after {
            color: var(--ion-color-primary-contrast) !important;
        }
        .data-list-item-inner-floating {
            mat-expansion-panel-header {
                background-color: var(--ion-color-primary);
                color: var(--ion-color-primary-contrast);
            }
            .mat-expansion-panel-header.mat-expanded:focus,
            .mat-expansion-panel-header.mat-expanded:hover,
            .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
                background-color: var(--ion-color-primary);
            }
            .mat-expansion-indicator::after {
                color: var(--ion-color-primary-contrast) !important;
            }
        }
    }
    .item-error {
        .data-list-item-title {
            background-color: var(--ion-color-danger) !important;
            color: var(--ion-color-danger-contrast) !important;
        }
        mat-expansion-panel-header,
        .mat-expansion-panel-header.mat-expanded:focus,
        .mat-expansion-panel-header.mat-expanded:hover,
        .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
            background-color: var(--ion-color-danger) !important;
            color: var(--ion-color-danger-contrast) !important;
        }
        .data-list-item-inner-floating {
            mat-expansion-panel-header {
                background-color: var(--ion-color-danger) !important;
                color: var(--ion-color-danger-contrast) !important;
            }
            .mat-expansion-panel-header.mat-expanded:focus,
            .mat-expansion-panel-header.mat-expanded:hover,
            .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
                background-color: var(--ion-color-danger) !important;
            }
            .mat-expansion-indicator::after {
                color: var(--ion-color-danger-contrast) !important;
            }
        }
    }
    .central-container {
        mat-tab-header {
            background: #d3d3d3;
            position: sticky;
            top: 0;
            z-index: 2;
            .mat-tab-label {
                height: auto !important;
                color: #333;
                opacity: 1;
                justify-content: flex-start;
                padding: 5px;
                border-left: 1px solid #92949c;
            }
            .mat-tab-label-active {
                background: var(--ion-color-tertiary);
                opacity: 1;
                color: #fff;
            }
            .mat-tab-label:focus:not(.mat-tab-disabled) {
                opacity: 1;
            }
            mat-ink-bar {
                background-color: var(--ion-color-secondary) !important;
            }
        }
    }
    app-input-group {
        .standard-field-item {
            padding-top: 5px;
        }
        .standard-fields {
            background: #f3f3f3;
            span {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                background: #fff;
                width: 100%;
                padding: 15px 10px 3px 30px;
                border-radius: 0px 5px 5px 5px;
                border: 1px solid #f1f1f1;
                border-top: none;
                font-size: 15px;
                word-break: break-all;
                cursor: text;
            }
        }
        .standard-field-item-rif {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background: #fff;
            width: 100%;
            padding: 15px 10px 3px 30px;
            border-radius: 0px 5px 5px 5px;
            border: 1px solid #f1f1f1;
            border-top: none;
            font-size: 15px;
            word-break: break-all;
            span {
                background: transparent;
                padding: 0px;
                border-radius: 0px;
                border: 0px;
                width: auto;
            }
        }
        .standard-field-item-label {
            span {
                padding: 0px 10px;
                background: #e6e6e6;
                border-radius: 5px 5px 0px 0px;
                font-weight: 450;
                color: var(--ion-color-primary);
                word-break: break-all;
                display: inline-flex;
                align-items: center;
                justify-content: flex-start;
                width: auto;
            }
        }
        .danger {
            span {
                color: var(--ion-color-danger);
            }
        }
    }
    .document-list-item-image-container {
        .cke_top {
            padding-right: 60px !important;
            position: sticky;
            top: 0;
        }
    }
}
.html-container-readonly {
    .cke_top,
    .cke_bottom {
        display: none !important;
    }
    .cke_chrome {
        border: 0px #fff solid !important;
    }
}
.qa-document-preview {
    .qa-document-preview-document {
        height: 100%;
        width: 100%;
        overflow: auto;
        padding: 10px;
    }
}
.qa-data-list-item {
    .html-container {
        .cke_top,
        .cke_bottom {
            display: none !important;
        }
        .cke_chrome {
            border: 0px #fff solid !important;
        }
    }
}
@media print {
    //print document
    .qa-document-preview {
        &::part(content)  {
            max-width: 100% !important;
            width: 100% !important;
            max-height: 100% !important;
            height: 100% !important;
        }
        ion-card-header {
            display: none !important;
        }
        .qa-document-preview-document {
            position: absolute !important;
            top: 0 !important;
            left: 0 !important;
            width: 100vw !important;
            overflow: visible !important;
            background-color: #fff !important;
            z-index: 99999 !important;
        }
        .cke_chrome {
            border: 0px #fff solid !important;
        }
    }
}
//ocr-differences-page

.ocr-differences-page{
    table{
    td{
    white-space: pre-wrap;
    }
    }
    }